import { Card } from "antd";
import React from "react";

const SelectUser = ({ setUser }) => {
  return (
    <div className="min-h-screen flex justify-center items-center gap-10">
      <Card className="cursor-pointer text-2xl font-semibold p-10 hover:border-blue-500" onClick={() => setUser("teacher")}>You're a Teacher</Card>
      <Card className="cursor-pointer text-2xl font-semibold p-10 hover:border-blue-500" onClick={() => setUser("student")}>You're a Student</Card>
    </div>
  );
};

export default SelectUser;
