import { Button, Modal, Tabs } from "antd";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdOutlineAssignment } from "react-icons/md";

const ToDo = () => {
  const Assigned = () => {
    const [submitAssignment, setSubmitAssignment] = useState(false);

    return (
      <div className="flex flex-col justify-center items-center gap-4">
        <button className="w-1/2 flex items-start gap-5 p-5 rounded-xl hover:border hover:drop-shadow-sm transition-all" onClick={() => setSubmitAssignment(true) }>
          <div className="p-1.5 round-full flex justify-center items-center bg-pink-500 rounded-full w-fit">
            <MdOutlineAssignment className="text-xl text-white" />
          </div>
          <div className="text-start">
            <h2 className="font-semibold">Assignment 1</h2>
            <h3 className="text-xs text-gray-500">Maths</h3>
            <h3 className="text-xs text-gray-500 mt-2">Posted Today</h3>
          </div>
        </button>

        <Modal title="Your Work" centered open={submitAssignment} okText='Mark as Done' onCancel={() => setSubmitAssignment(false)}>
          <div className="space-y-3">
            <Button><FaPlus />Add or create</Button>
          </div>
        </Modal>
      </div>
    );
  };

    const Missing = () => {
      return (
          <div className="flex justify-center items-center">
              <div>Looks like nothing is missing. Nice work!</div>
          </div>
      );
    };

    const Done = () => {
      return (
          <div className="flex justify-center items-center">
              <div>Your work will show here once it’s turned in or marked as done</div>
          </div>
      );
    };

  const items = [
    {
      key: "1",
      label: "Assigned",
      children: <Assigned />,
    },
    {
      key: "2",
      label: "Missing",
      children: <Missing />,
    },
    {
      key: "3",
      label: "Done",
      children: <Done />,
    },
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        className="bg-white rounded-lg p-4"
      />
    </div>
  );
};

export default ToDo;
