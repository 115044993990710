import { Tooltip } from "antd";
import React from "react";
import { BsPersonBadge } from "react-icons/bs";
import { PiArrowLineUpRightBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const Home = ({ user, classes, setClasses }) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-5">
      {classes.map((item) => (
        <div
          key={item.key}
          className="w-72 h-64 border rounded-lg bg-white cursor-pointer flex flex-col items-center"
          onClick={() => navigate('/subject')}
        >
          <div className="relative">
            <img
              src={item.img ? item.img : 'https://www.gstatic.com/classroom/themes/img_learnlanguage.jpg' }
              alt=""
              className="rounded-t-lg h-20 object-cover"
            />
            <div className="text-white absolute top-4 left-4">
              <h2 className="text-lg">{item.name}</h2>
              <h3>{item.div}</h3>
            </div>
          </div>
          <div className="p-4 w-full h-full">
            <h3 className="text-xs text-gray-400">Due Today</h3>
            <h3 className="text-sm">Test Exam</h3>
          </div>
          <div className="p-4 border-t w-full flex justify-end items-center gap-3">
            {user === "student" && <Tooltip title={`Open your work for "${item.name}"`}>
              <button>
                <BsPersonBadge className="text-xl" />
              </button>
            </Tooltip>}
            {user === "teacher" && <Tooltip title={`Open gradebook for "${item.name}"`}>
              <button>
                <PiArrowLineUpRightBold className="text-xl" />
              </button>
            </Tooltip>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;
