import { Input, Switch } from "antd";
import React from "react";

const CheckSubmissions = () => {
  return (
    <div className="bg-white p-4 rounded-md h-full flex">
      <div className="w-1/4 border-r shrink-0">
        <div className="flex justify-between items-center p-4 hover:border-y">
          <div className="flex items-center gap-4">
            <div className="size-8 bg-blue-500 rounded-full"></div>
            Student 1
          </div>
          <Input defaultValue='70' className="w-20" suffix="/100" />
        </div>
        <div className="flex justify-between items-center p-4 hover:border-y">
          <div className="flex items-center gap-4">
            <div className="size-8 bg-blue-500 rounded-full"></div>
            Student 2
          </div>
          <Input className="w-20" suffix="/100" />
        </div>
      </div>

      <div className="w-full p-4">
        <h1 className="text-2xl font-medium">Test 1</h1>
        <div className="mt-5 flex items-center gap-7">
          <div>
            <h1 className="text-3xl font-semibold">0</h1>
            <h2 className="text-xs text-gray-500">Turned in</h2>
          </div>
          <div>
            <h1 className="text-3xl font-semibold">0</h1>
            <h2 className="text-xs text-gray-500">Assigned</h2>
          </div>
          <div>
            <h1 className="text-3xl font-semibold">1</h1>
            <h2 className="text-xs text-gray-500">Graded</h2>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-5">
            <Switch defaultChecked/>
            <h2>Accept Submissions</h2>
        </div>
        <div className="mt-8 flex flex-col gap-3">
            <button className="text-base px-4 p-2 border rounded-lg w-fit hover:border-blue-500">
                Student 1's Submission
            </button>
            <button className="text-base px-4 p-2 border rounded-lg w-fit hover:border-blue-500">
                Student 2's Submission
            </button>
        </div>
      </div>
    </div>
  );
};

export default CheckSubmissions;
