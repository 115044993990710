import { Dropdown, Input, Layout, Menu, Modal } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React, { useState } from "react";
import { AiOutlineHome, AiOutlinePlus } from "react-icons/ai";
import { BsCardChecklist } from "react-icons/bs";
import { LuFolderClosed, LuUsers } from "react-icons/lu";
import { RiGraduationCapLine } from "react-icons/ri";
import { Outlet, useNavigate } from "react-router-dom";

const MainLayout = ({ user, classes, setClasses }) => {
  const navigate = useNavigate();
  const [joinModal, setJoinModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [createDetails, setCreateDetails] = useState({ name:'', div:''})

  const items = [
    {
      key: 1,
      icon: <AiOutlineHome />,
      label: "Home",
      onClick: () => navigate("/"),
    },
    {
      type: "divider",
    },
    ...(user === 'teacher' ? [ // Check if user is a teacher
      {
        key: 2,
        icon: <LuUsers />,
        label: "Teaching",
        children: [
          {
            key: 4,
            label: "To Review",
            icon: <LuFolderClosed />,
            onClick: () => navigate("/to-review"),
          },
          ...classes.map((item) => ({
            key: item.key,
            label: item.name + ' ' + '-' + ' ' + item.div,
            onClick: () => navigate(`/subject`),
          })),
        ],
      },
    ] : []),
    ...(user === 'student' ? [
    {
      key: 3,
      icon: <RiGraduationCapLine />,
      label: "Enrolled",
      children: [
        {
          key: 5,
          label: "To-do",
          icon: <BsCardChecklist />,
          onClick: () => navigate("/to-do"),
        },
        ...classes.map((item) => ({
          key: item.key,
          label: item.name + ' ' + '-' + ' ' + item.div,
          onClick: () => navigate(`/subject`),
        })),
      ],
    },
  ] : []),
  ];

  const items2 = [
    {
      key: '1',
      label: 'Join Class',
      onClick: () => setJoinModal(true),
    },
    {
      key: '2',
      label: 'Create Class',
      onClick: () => setCreateModal(true),
    },
  ];

  return (
    <Layout className="min-h-screen">
      <Header className="flex justify-between items-center bg-white border-b">
        <h2 className="text-lg">Classroom</h2>
        <Dropdown menu={{items: items2,}}>
          <button>
            <AiOutlinePlus className="text-xl" />
          </button>
        </Dropdown>
      </Header>
      <Layout>
        <Sider width={250} style={{ background: "white" }} className="border-r">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={items}
          />
        </Sider>
        <Layout>
          <Content className="bg-gray-100 p-5">
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      <Modal
        title="Join Class"
        centered
        open={joinModal}
        okText="Join"
        onCancel={() => setJoinModal(false)}
      >
        <div className="space-y-3">
          <h2>Ask your teacher for the class code, then enter it here.</h2>
          <Input placeholder="Class Code" />
        </div>
      </Modal>

      <Modal
        title="Create Class"
        centered
        open={createModal}
        okText="Create"
        onCancel={() => setCreateModal(false)}
        onOk={() => {setClasses([...classes, {key: classes.length + 1, name: createDetails.name, div: createDetails.div}]); setCreateDetails({name:'', div:""}); setCreateModal(false)}}
      >
        <div className="space-y-3">
          <Input placeholder="Class Name" value={createDetails.name} onChange={(e) => setCreateDetails({...createDetails, name: e.target.value})} />
          <Input placeholder="Section" value={createDetails.div} onChange={(e) => setCreateDetails({...createDetails, div: e.target.value})} />
          <Input placeholder="Subject" />
        </div>
      </Modal>
    </Layout>
  );
};

export default MainLayout;
