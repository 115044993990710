import { Button, Dropdown, Input, Modal, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { MdOutlineAssignment } from "react-icons/md";
import { SiGooglemeet } from "react-icons/si";
import { useNavigate } from "react-router-dom";

const Subject = ({ user }) => {
  const navigate = useNavigate();

  const Stream = () => {
    return (
      <div className="flex justify-center items-center text-center">
        <div className="w-3/5 space-y-5">
          <div className="relative">
            <img
              src="https://www.gstatic.com/classroom/themes/Math.jpg"
              alt=""
              className="w-full object-cover rounded-lg"
            />
            <div className="text-white absolute bottom-5 left-5 text-start">
              <h1 className="text-4xl font-semibold">Maths</h1>
              <h1 className="text-2xl font-medium mt-1">A</h1>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/4 space-y-5">
              <div className="border rounded-md p-4">
                <div className="flex items-center gap-2">
                  <SiGooglemeet className="text-lg" />
                  Meet
                </div>
                <button className="bg-blue-500 text-white p-1.5 rounded-md w-full mt-3">
                  Join
                </button>
              </div>
              <div className="border rounded-md p-4">
                <div className="flex items-center gap-2">Class code</div>
                <button className="text-blue-500 flex text-xl font-semibold mt-2">
                  wpdcykd
                </button>
              </div>
            </div>
            <div className="w-full space-y-5">
              <div className="border p-3 rounded-md">
                <div className="flex items-center gap-3">
                  <div className="p-2 round-full flex justify-center items-center bg-blue-500 rounded-full w-fit">
                    <MdOutlineAssignment className="text-2xl text-white" />
                  </div>
                  <div className="text-start">
                    <h2 className="font-semibold">
                      Jerome Joseph posted a new assignment: Test 2
                    </h2>
                    <h3 className="text-xs text-gray-500">
                      8:30 PM (Edited 11:00 PM)
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Classwork = () => {
    const [assignmentModal, setAssignmentModal] = useState(false);
    const [submitAssignment, setSubmitAssignment] = useState(false);

    const items = [
      {
        key: '1',
        label: 'Assignment',
        onClick: () => setAssignmentModal(true),
      },
      {
        key: '2',
        label: 'Quiz Assignment',
      },
      {
        key: '3',
        label: 'Question',
      },
    ];

    const checkUser = () => {
      if(user === 'teacher') navigate('/check-submissions')
      else setSubmitAssignment(true)
    }
    
    return (
      <div className="flex justify-center items-center text-center">
        <div className="w-1/2 text-start space-y-5">
          {user === "teacher" && <Dropdown menu={{items,}}>
            <Button type="primary" size="large">
              <AiOutlinePlus className="text-xl" />
              Create
            </Button>
          </Dropdown>}
          <div>
            <div className="flex justify-between items-center p-3 rounded-md hover:border hover:drop-shadow-sm cursor-pointer" onClick={checkUser}>
              <div className="flex items-center gap-4">
                <div className="p-1.5 round-full flex justify-center items-center bg-blue-500 rounded-full w-fit">
                  <MdOutlineAssignment className="text-2xl text-white" />
                </div>
                <h2 className="font-semibold">Test Exam</h2>
              </div>
              <h2 className="text-sm text-gray-500">Due Today</h2>
            </div>
          </div>
        </div>
        <Modal title="Create Assignment" centered open={assignmentModal} okText='Create' onCancel={() => setAssignmentModal(false)}>
          <div className="space-y-3">
            <Input placeholder="Title" />
            <TextArea rows={4} placeholder="Instructions (Optional)" />
            <Input placeholder="Points" />
          </div>
        </Modal>
        <Modal title="Your Work" centered open={submitAssignment} okText='Mark as Done' onCancel={() => setSubmitAssignment(false)}>
          <div className="space-y-3">
            <Button><FaPlus />Add or create</Button>
          </div>
        </Modal>
      </div>
    );
  };

  const People = () => {
    return (
      <div className="flex justify-center items-center text-center">
        <div className="w-1/2 text-start">
          <div className="space-y-3">
            <h1 className="text-3xl font-medium border-b pb-2">Teachers</h1>
            <div>
              <div className="flex justify-between items-center p-3 rounded-md hover:border hover:drop-shadow-sm cursor-pointer">
                <div className="flex items-center gap-4">
                  <div className="size-9 bg-blue-500 rounded-full"></div>
                  <h2>Jerome Joseph</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3 mt-16">
            <h1 className="text-3xl font-medium border-b pb-2">Students</h1>
            <div>
              <div className="flex justify-between items-center p-3 rounded-md hover:border hover:drop-shadow-sm cursor-pointer">
                <div className="flex items-center gap-4">
                  <div className="size-9 bg-blue-500 rounded-full"></div>
                  <h2>Jerome Joseph</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Grades = () => {
    return (
      <div className="flex justify-center items-center text-center">
        <div>
            This is where you’ll view and manage grades
        </div>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Stream",
      children: <Stream />,
    },
    {
      key: "2",
      label: "Classwork",
      children: <Classwork />,
    },
    {
      key: "3",
      label: "People",
      children: <People />,
    },
    ...(user === 'teacher' ? [{
      key: "4",
      label: "Grades",
      children: <Grades />,
    }] : []),
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        className="bg-white rounded-lg p-4"
      />
    </div>
  );
};

export default Subject;
