import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout';
import Home from './pages/home';
import ToDo from './pages/to-do';
import ToReview from './pages/to-review';
import Subject from './pages/subject';
import SelectUser from './pages/select-user';
import { useState } from 'react';
import CheckSubmissions from './pages/check-submissions';

function App() {
  const [user, setUser] = useState();
  const [classes, setClasses] = useState([
    {
      key: 1,
      name: "Maths",
      div: "E",
      img: 'https://www.gstatic.com/classroom/themes/Math.jpg'
    },
    {
      key: 2,
      name: "English",
      div: "A",
      img: 'https://www.gstatic.com/classroom/themes/img_learnlanguage.jpg'
    },
  ]);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={!user ? <SelectUser setUser={setUser} /> : <MainLayout user={user} classes={classes} setClasses={setClasses} />} >
          <Route index element={<Home user={user} classes={classes} setClasses={setClasses} />} />
          <Route index path="to-do" element={<ToDo />} />
          <Route index path="to-review" element={<ToReview />} />
          <Route index path="subject" element={<Subject user={user} />} />
          <Route index path="check-submissions" element={<CheckSubmissions />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;