import { Modal, Tabs } from "antd";
import React, { useState } from "react";
import { MdOutlineAssignment } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ToReview = () => {
  const navigate = useNavigate();

  const Review = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-4">
        <button className="w-1/2 flex justify-between items-start gap-5 p-5 rounded-xl hover:border hover:drop-shadow-sm transition-all" onClick={() => navigate('/check-submissions')}>
          <div className="flex items-center gap-5">
            <div className="p-1.5 round-full flex justify-center items-center bg-blue-500 rounded-full w-fit">
              <MdOutlineAssignment className="text-xl text-white" />
            </div>
            <div className="text-start">
              <h2 className="font-semibold">Test 1</h2>
              <h3 className="text-xs text-gray-500">English · Due Today</h3>
            </div>
          </div>
          <div className="text-start flex items-center gap-8">
            <div>
                <h1 className="text-xl font-semibold">0</h1>
                <h2 className="text-xs text-gray-500">Turned in</h2>
            </div>
            <div>
                <h1 className="text-xl font-semibold">0</h1>
                <h2 className="text-xs text-gray-500">Assigned</h2>
            </div>
            <div>
                <h1 className="text-xl font-semibold">0</h1>
                <h2 className="text-xs text-gray-500">Graded</h2>
            </div>
          </div>
        </button>
      </div>
    );
  };

  const Reviewed = () => {
    return (
      <div className="flex justify-center items-center text-center">
        <div>No reviewed work yet <br />
        This is where you’ll see work you’ve marked as reviewed</div>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "To review",
      children: <Review />,
    },
    {
      key: "2",
      label: "Reviewed",
      children: <Reviewed />,
    },
  ];

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        className="bg-white rounded-lg p-4"
      />
    </div>
  );
};

export default ToReview;
